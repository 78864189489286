import React, { useState, useRef, useEffect } from 'react';
import { editLogoImage } from '../../../store/actions/business';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchMyBusiness } from '../../../store/actions/business';
import headerImage from 'assets/img/image_placeholder.jpg';
// reactstrap components
import {
	Container,
	Row,
	Col,
	Card,
	CardBody,
	CardTitle,
	Button,
	UncontrolledTooltip,
} from 'reactstrap';
// core components
import Navbar from 'views/Components/NavbarAuth';
import Header from 'views/BusinessPage/Sections/SectionHeader';
import BlogPostSection from './SectionBlogPost';
import ProductForms from '../Modals/ProductForms';
import FooterAuth from 'views/Components/Footer';

import * as yup from 'yup';
// import ImageInput from '../../Components/ImageInput';
const FILE_SIZE = 1e6;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

const schema = yup.object({
	// title: yup.string().required('required'),
	// description: yup.string().required('required'),
	files: yup
		.mixed()
		.nullable()
		.required('Image is required')
		.test(
			'fileSize',
			'File too large, select Images smaller than 1MB',
			(value) => value && value.size <= FILE_SIZE
		)
		.test(
			'fileFormat',
			'Unsupported Format',
			(value) => value && SUPPORTED_FORMATS.includes(value.type)
		),
});

function ProfilePage(rest) {
	const dispatch = useDispatch();
	const business = useSelector((state) => state.myBusiness);
	const { id } = useSelector((state) => state.currentUser.user);
	// const errors = useSelector((state) => state.errors);
	const [image, setImage] = useState(undefined);
	const [validIt, setValidIt] = useState(undefined);
	useEffect(() => {
		if (id) {
			dispatch(fetchMyBusiness(id)).catch((err) => {
				console.error('Failed to fetch business data:', err);
			});
		}
	}, [id, dispatch]);
	useEffect(() => {
		try {
			dispatch(rest.removeError());
			dispatch(rest.removeSuccess());
		} catch (err) {
			console.log(err);
		}
	}, []);
	//HANDLE LOGO CHANGING===================
	const inputFile = useRef(null);
	const handleClickImage = () => {
		dispatch(editLogoImage(id, image));
		setImage(undefined);
	};
	const handleClearInputFile = () => {
		inputFile.current.value = '';
		setImage('');
	};
	// =======================================
	document.documentElement.classList.remove('nav-open');
	useEffect(() => {
		document.body.classList.add('profile-page');
		return function cleanup() {
			document.body.classList.remove('profile-page');
		};
	});
	console.log(validIt);
	return (
		<>
			<Navbar />
			<Header business={business} />
			<div className='wrapper'>
				<div className='profile-content section'>
					<Container>
						<Row>
							<div className='profile-picture'>
								<div
									className='fileinput fileinput-new'
									data-provides='fileinput'>
									<div className='fileinput-new img-no-padding'>
										{!image && (
											<>
												<img alt='...' src={business.logo || headerImage} />
												<Button
													className='btn-just-icon mt-5'
													color='success'
													id='tooltip437036653'
													size='sm'
													onClick={() => inputFile.current.click()}
													type='button'>
													<i className='nc-icon nc-settings-gear-65' />
												</Button>
												<UncontrolledTooltip
													delay={0}
													placement='right'
													target='tooltip437036653'>
													Edit
												</UncontrolledTooltip>
											</>
										)}
										<input
											ref={inputFile}
											type='file'
											style={{ display: 'none' }}
											onChange={(e) => {
												setImage(e.target.files[0]);
												setValidIt(undefined);
												schema
													.validate({
														files: e.target.files[0],
													})
													.catch((err) => {
														setImage(undefined);
														setValidIt(err.errors);
													});
											}}
										/>
										{image && (
											<>
												<div className='post-preview'>
													<img
														className='post-preview-img'
														src={URL.createObjectURL(image)}
														alt='preview'
													/>
													<i
														onClick={handleClearInputFile}
														className='bi bi-x-circle'
													/>
													<i
														onClick={handleClickImage}
														className='bi bi-check-circle'
													/>
												</div>
											</>
										)}
									</div>
									<div className='name'>
										<h4 className='title text-center'>
											<strong>{business.businessName}</strong>
										</h4>
										{/* <h4 className='title text-center'>
											<small>{business.subTitle}</small>
										</h4> */}
									</div>
								</div>
							</div>
						</Row>
						<div>
							<Container>
								{rest.errors.message && (
									<div className='alert alert-danger'>
										{rest.errors.message}
									</div>
								)}

								<Row>
									<Col>
										<h3 className='section-title mb-5'>
											<strong>Latest Offers</strong>
										</h3>
									</Col>
									<Col>
										<ProductForms />
									</Col>
								</Row>

								<Row>
									{business.products.map((product) => (
										<>
											<Col md='4'>
												<Card className='card-product card-plain'>
													<div className='card-image'>
														<Link
															to={{
																pathname: `/my-product-page/${product._id}`,
															}}>
															<img
																alt='...'
																className='img-rounded img-responsive'
																src={product.image[0]}
															/>
														</Link>
														<CardBody>
															<div className='card-description'>
																<CardTitle tag='h5'>{product.title}</CardTitle>
																<p className='card-description'>
																	{product.description}
																</p>
															</div>
															<div className='price'>
																{/* <s className='mr-1'>3.520 €</s> */}
																<span className='text-dark'>
																	CD$ {product.price}
																</span>
															</div>
														</CardBody>
													</div>
												</Card>
											</Col>
										</>
									))}
								</Row>
							</Container>
						</div>
						{/* End of Section Products */}
						{/* Section Blog Post */}
						<BlogPostSection businessNewsFeeds={business.newsFeeds} />
						{/* End of Section Blog Post */}
					</Container>
				</div>
				<FooterAuth />
			</div>
		</>
	);
}

export default ProfilePage;
